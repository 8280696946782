import React from "react";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import AllRoutes from './routers/routes';
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {


  const authorizedOrigins = ['http://localhost', `http://localhost:3000}`, `https://fidalgo.co.in/`];
  



  return (
    <div className="App">
      <GoogleOAuthProvider clientId="967864324380-6ahdf2pko9rj3fel1ogo3drq47pb93pm.apps.googleusercontent.com"  authorizedOrigins={authorizedOrigins}>
      <AllRoutes/>
      <ToastContainer autoClose={800}/>
      </GoogleOAuthProvider>
      
    </div>
  );
}

export default App;
