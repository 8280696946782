import React, { useEffect, useState } from 'react'

import Header from '../components/Header'

import Footer from '../components/Footer'
import api from '../axios/api';
import { useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom'

export default function Account() {
  const accessToken = useSelector((store) => store.isAccessToken);
  const [trackOrder, setTrackOrder] = useState(null);

  const navigate = useNavigate();
  
  useEffect(() => {

    const OrderDetail = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_BASE_URL}orders-list`, accessToken);
        setTrackOrder(response?.data?.data);

      }
      catch (error) {
        console.error("API error:", error);
      }
    };

    OrderDetail();
  }, [accessToken])


  

  const viewmore=(id)=>{
// console.log(item,"viewmore")


navigate(`/orderDetail/${id}`)

  }

  if (trackOrder === null) return <Loader />

  return (
    <div className="wrapper account_wrapper">

      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>

      

      <div className="account_section_outer">
        <h1>My Orders</h1>
        <div className="sec_container">
          <div className="account_section_inner">
            {trackOrder.map((item, id) => (
              <div>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                <b>Order I'd : {item?.id}</b>
                <div style={{textAlign:'right'}}>Order Amount : <b style={{color:'#000',fontWeight:600}}>{ item?.final_amount}</b></div>
                </div>
                
                <div className='address'>Address : {item?.address1} {item?.address2}</div>

                <div className='address city'>City : {item?.city} </div>
                <div className='address state'>State : {item?.state} </div>
                <div className='address pincode'>Pin Code : {item?.pincode} </div>
                <div className='address pincode'>Phone Number : {item?.mobile_number} </div>
                
                <div className="status_outer">
                  <div className='status'>
                    Status :
                    <b>
                      {item?.status === 1 ? ' Confirmed' :
                        item?.status === 2 ? ' Dispatched'
                          : ' Delivered'}
                    </b>

                  </div>
                  <span className="view_more " onClick={()=>viewmore(item?.id)}>View More <i class="fa-solid fa-arrow-right-long"></i></span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  )
}
