import axiosInstance from './axiosInstance';


const api = {
  get: (url, authToken = null) => axiosInstance.get(url, authToken ? { headers: { Authorization: `Bearer ${authToken}` } } : {}),
  post: (url, data, authToken = null) => axiosInstance.post(url, data, authToken ? { headers: { Authorization: `Bearer ${authToken}` } } : {}),
  put: (url, data, authToken = null) => axiosInstance.put(url, data, authToken ? { headers: { Authorization: `Bearer ${authToken}` } } : {}),
  delete: (url, authToken = null) => axiosInstance.delete(url, authToken ? { headers: { Authorization: `Bearer ${authToken}` } } : {}),
};

export default api;
