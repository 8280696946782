"use client"

import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import { Base64 } from 'js-base64';
import { SHA256 } from 'crypto-js';
// import { useNavigate } from 'react-router-dom';

const PhonePePayment = () => {
  // const [paymentUrl, setPaymentUrl] = useState('');
  
  // const navigate = useNavigate();

  const myUrl = 'http://localhost:3000/'
  const initiatePayment=useCallback(async()=>{
    

    try {
    
      const apiUrl =  'https://api.phonepe.com/apis/hermes/pg/v1/pay';
      // const apiUrl =  'https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay';
      const transactionid = 'T' + Date.now();
      const apiPayLoadData={
      merchantId: "PGTESTPAYUAT",
      merchantTransactionId:'T' + Date.now(),
      merchantUserId: "MUID123",
      amount: 1,
      redirectUrl:`${myUrl}/paymentprocess?id=${transactionid}`,
  
      redirectMode: "POST",
      callbackUrl: `${myUrl}`,

      mobileNumber: "999999999",
      paymentInstrument: {
        type: "PAY_PAGE",
      },
    }
    
    

    
      const apiPayLoadDataJson = JSON.stringify(apiPayLoadData)
    
      const apiPayLoadDataBase64 = Base64.encode(apiPayLoadDataJson)
      // const apiPayLoadDataBase64 = btoa(apiPayLoadDataJson)
      const saltKey='099eb0cd-02cf-4e2a-8aca-3e6c6aff0399'
      const saltIndex = '1'
    
      // const shaKey=   SHA256`${(apiPayLoadDataBase64 + '/pg/v1/pay' + saltKey)} + ### + ${saltIndex}`


      const fullURL = apiPayLoadDataBase64 + "/pg/v1/pay" + saltKey;
      
     const dataSha256 = SHA256(fullURL);
    
     const shaKey = dataSha256 + "###" + saltIndex;
      const response = await axios.post(
        apiUrl,
        {
         request: apiPayLoadDataBase64,
        },
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            'X-VERIFY':shaKey,
          },
        }
      );

      
      
      const redirect=response.data.data.instrumentResponse.redirectInfo.url;

      console.log(redirect,"redirect")

      
      // navigate(`/${redirect}`)
      window.location.href = redirect
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  
    },[]);
  
  

  useEffect(() => {
    initiatePayment();
  }, [initiatePayment]);


  return (
    <div>
      {/* <button onClick={initiatePayment}>Initiate Payment</button> */}
      {/* {paymentUrl && <iframe title="PhonePe Payment" src={paymentUrl} width="100%" height="600px"></iframe>} */}
    </div>
  );
};

export default PhonePePayment;

