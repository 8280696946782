import React, { useEffect, useState } from 'react'
import api from '../axios/api';
import {  useNavigate } from 'react-router-dom';


export default function CategoryNav() {
    const [catDataList,setCatDataList]= useState('');


    const Navigate = useNavigate()
    const catList = async () => {
        try {
          const response = await api.get(`${process.env.REACT_APP_BASE_URL}category`);
          setCatDataList(response?.data?.data)
          // Handle the response data as needed, e.g., set it to state
        } catch (error) {
          console.error('API error:', error);
          // Handle the error, e.g., set an error state or show an error message
        }
      };
    
        
        
    
    
    useEffect((i)=>{
      catList();
    },[])
    

const searchCateogy=(slug)=>{
  console.log(slug,"slug")
    Navigate(`/categories/${slug}`);
    
}
    
  return (
    <>
    {catDataList.length>0 &&
        <ul className="product_cat_nav">
          {catDataList?.map((item) => (
          <li key={item?.id}>
            <span onClick={()=>searchCateogy(item?.slug)}>{item?.name}</span>
          </li>
          )
          )}
        </ul>
        }
        </>
  )
}
