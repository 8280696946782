import React, { useEffect, useState } from 'react'
import api from '../../axios/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export default function AddAddress({setAddNewAddress,AddressList,editAddress}) {
  const [formData, setFormData] = useState({
    name: "",
    address1: "",
    city: "",
    state: "",
    pincode:"",
    mobile_number:""
  });

  useEffect(() => {
    // If editAddress exists, populate form data with its values
    if (editAddress) {
      setFormData(editAddress);
    }
  }, [editAddress]);

  const [mobileError, setMobileError] = useState('');
  const [pinError, setPinError] = useState('');


  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'mobile_number' && value.length !== 10) {
      setMobileError('Mobile number must be of 10 digits.');
    }else {
       setMobileError('');
    }
    
    if (name === 'pincode' && value.length !== 6){
      setPinError('Pin Code Must be of 6 digit');
    } else {
      setPinError('');
    }
  };

  const accessToken = useSelector((store) => store.isAccessToken);

  const handleSubmit = async (e) => {
    e.preventDefault();    
      try {
        let response
        if (editAddress) {
          
          const postData = { ...formData, id: editAddress.id };
          response = await api.post(`${process.env.REACT_APP_BASE_URL}updateAddress`, postData, accessToken);
        } else {
          // Otherwise, it's an add request
          const postData = { name: formData.name, address1: formData.address1, city: formData.city, state: formData.state, pincode: formData.pincode, mobile_number: formData.mobile_number };
          response = await api.post(`${process.env.REACT_APP_BASE_URL}addAddress`, postData, accessToken);
        }

        
         if(response.status === 200){
          toast.success(response?.data?.message,{toastId: "1"});
          setFormData({
            name: "",
            address1: "",
            city: "",
            state: "",
            pincode:"",
            mobile_number:""
          });

          setAddNewAddress(false);
          AddressList();
         }
         
  
      } catch (error) {
        console.error('API error:', error);
      
      }
    
  };

  return (
    <>
    <div className='add_address_box'>
    <form onSubmit={handleSubmit}>
      <div className="form_flex">
                  <div className="form_field ">
                    <label htmlFor="">Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_field ">
                    <label htmlFor="Email Address">Enter Your Address *</label>
                    <input
                      type="text"
                      placeholder="Enter Your Address"
                      id="address1"
                      name="address1"
                      value={formData.address1}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  </div>
                  <div className="form_flex">
                  <div className="form_field">
                    <label htmlFor="">Enter Your City *</label>
                    <input
                      type="text"
                      placeholder="Enter Your City"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_field">
                    <label htmlFor="">Enter Your State *</label>
                    <input
                      type="text"
                      placeholder="Enter Your State"
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  </div>
                  <div className="form_flex">
                  <div className="form_field">
                    <label htmlFor="">Enter Your Pincode *</label>
                    <input
                      type="number"
                      placeholder="Enter Your Pincode"
                      id="pincode"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleChange}
                      required
                    />
                    
                    {pinError && (
        <div style={{ color: 'red', fontSize: '14px' }}>{pinError}</div>
      )}
                  </div>
                  <div className="form_field">
                    <label htmlFor="">Enter Your Mobile Number *</label>
                    <input
                    type="number"
                      placeholder="Enter Your Mobile Number"
                      id="mobile_number"
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={handleChange}
                      required
                    />
                        {mobileError && (
        <div style={{ color: 'red', fontSize: '14px' }}>{mobileError}</div>
      )}
                  </div>
                  </div>
                  
<div className="form_field">
                  <button type="submit" disabled={mobileError}>{editAddress ? 'Update Address':'Add Address'}</button>
                  </div>
                </form>

    </div>
    </>
  )
}
