import React from "react";

export default function CartSteps(props) {
  const {
    shoppingcartImg,
    isCartPageActive,
    checkoutImg,
    orderImg,
    isCheckoutActive,
    isOrderActive,
    isDone,
  } = props;
  return (
    <div className="cart_steps_outer">
      <div className="sec_container">
        <ul className="cart_steps_inner">
          <li className={isDone ?"Done":""}>
            <img src={shoppingcartImg} alt="" />
            <span className={isCartPageActive ? "bold_text" : ""}>
              Shopping Cart
            </span>
          </li>
          <li className={isDone ?"Done":""}>
            <img src={checkoutImg} alt="" />
            <span className={isCheckoutActive ? "bold_text" : ""}>
              Checkout
            </span>
          </li>
          <li className={isDone ?"Done":""}>
            <img src={orderImg} alt="" />
            <span className={isOrderActive ? "bold_text" : ""}>
              Order Complete
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}
