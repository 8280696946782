import React, { useEffect, useState } from 'react'
import api from '../axios/api';
import Footer from '../components/Footer';

import Header from '../components/Header';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

export default function Recipes() {
  const [recipesList,setRecipesList]=useState(null)
  

  useEffect(() => {
    const Recipes = async () => {
      try {

        const response = await api.get(`${process.env.REACT_APP_BASE_URL}recipies-list`);
        
        
        setRecipesList(response?.data?.data?.data);

      }
       catch (error) {
        
        console.error("API error:", error);

      }
    };
    Recipes();

  }, [])

  
  return (
    
  
  
  
    <div className='wrapper '>
            <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
          
        
      <div className="about_wrapper">
      <div className="sec_container">
        <div className="about_inner blogs_inner">
        <h1>Our Recipes</h1>
          <div className="outlets ">
            
          {recipesList !== null ?
          recipesList.map((item,id)=>(
<div className='outlets_inner blog_box recipes_box' key={id}>
<Link to={`/recipesDetail/${item.slug}`}>
  <img src={`${process.env.REACT_APP_FOR_API_URL}${item?.image}`} alt="" />
  
  <div className='name'>{item?.title}</div>
  
  </Link>
  </div>
))

  :<Loader/>        
        }
        </div>
        </div>
      </div>
      </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
      
    </div>
  )


  

}
