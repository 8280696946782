import React from "react";
import { Link } from "react-router-dom";
import BackToTopButton from "./BackToTopButton";
import WhatsAppButton from "./WhatsAppButton";

export default function Footer() {
  return (
    <div className="footer_inner">
      <div className="foot_top">
        <div className="foot_logo">
        <img src="/assets/images/logo.svg" alt="" />
          <p>Industrial Area C, Dhandari Kalan, Ludhiana, Punjab 141016</p>
          <p>Phone no.- 7009326717, 7702315034, 8427109797</p>
        </div>
        <div className="foot_nav">
          <div>
            <span>QUICK LINK</span>
            <ul>
              <li>
                <Link to="/">Track Your Order</Link>
              </li>
              <li>
                <Link to="/categories">All Products</Link>
              </li>
              <li>
                <Link to="/about">About US</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/cart">Cart</Link>
              </li>
              {/* <li>
                <Link to="/">Support</Link>
              </li> */}
              {/* <li>
                <Link to="/">Reward</Link>
              </li> */}
              <li>
                <Link to="/bloglisting">Blog</Link>
              </li>
              <li>
                <Link to="/faqs">Shipping Faq</Link>
              </li>
            </ul>
          </div>
          <div>
          <span>WRITE TO US</span>
            <ul>
              <li>
                <a href="mailto:customercare@fidalgo.co.in">customercare@fidalgo.co.in</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="foot_bottom">
        <div>
          <span>© 2022 NOGLU All Rights Reserved. </span>
        </div>
        <ul>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/refundpolicy">Refund Policy</Link>
          </li>
        </ul>
      </div>
      <BackToTopButton/>
      <WhatsAppButton/>
    </div>
  );
}
