export const convertToLocalTime = (utcDate) => {
    const dateObject = new Date(utcDate);
    // const localDateString = dateObject.toLocaleString(); 
    const localDateString = dateObject.toLocaleString('en-US', {
        // weekday: 'long',
        year: 'numeric',
        month: 'long',
        
        day: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // second: 'numeric',
        // timeZoneName: 'short',
      });
  
    return localDateString;
  };
  


  export const percentageDisc=(ActualPrice,DiscountedPrice)=>{


    const difference =  ActualPrice - DiscountedPrice
    const PercentDiscount = Math.round((difference/ActualPrice)* 100)

    return PercentDiscount
    
  }
