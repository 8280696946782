import React, { useEffect, useState } from 'react'
import api from '../axios/api';
import Header from './Header';

import Footer from './Footer';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
export default function Faqs() {
    const [faqs,setFaq]= useState('');
  useEffect(() => {
    const Privacy = async () => {
      try {

        const response = await api.get(`${process.env.REACT_APP_BASE_URL}faqs`);
        
        
        setFaq(response?.data?.data?.faqs?.data)

      }
       catch (error) {
        
        console.error("API error:", error);

      }
    };
    Privacy();

  }, [])
  return (
    
        <div className='wrapper '>
            <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
      <div className="faqs">
        <h1>Frequently Asked Questions</h1>
        <div className="sec_container">
            <div className="faq_inner" style={{margin:'50px 0px'}}>
          <Accordion allowZeroExpanded>

          {faqs && faqs.map((item)=> (
     <AccordionItem key={item.uuid}>
     <AccordionItemHeading>
         <AccordionItemButton>
             {item.question}
         </AccordionItemButton>
     </AccordionItemHeading>
     <AccordionItemPanel>
       {item.answer}
     </AccordionItemPanel>
 </AccordionItem>
          ))}
            </Accordion>
            </div>
            </div>
            </div>
            <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
      
    </div>
  )
}
