import React, { useEffect, useState } from 'react'
import Header from '../Header'

import Footer from '../Footer'
import api from '../../axios/api'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function OrderCompleted() {
  


  const accessToken = useSelector((store) => store.isAccessToken);
  let url = window.location.href;
let lastIndex = url.lastIndexOf('/');
let orderId = url.substring(lastIndex + 1);
  
  const [orderCompleted,setOrderCompleted]=useState(null);
  useEffect(()=>{
    const OrderDetail = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_BASE_URL}orders-list/${orderId}`,accessToken);
        setOrderCompleted(response?.data?.data);
        
      }
      catch (error) {
        console.error("API error:", error);
    }
  };

  OrderDetail();
  },[orderId,accessToken])

  console.log(orderCompleted,"orderCompleted")
  return (
    <div className="order_completed">
      <div className='wrapper '>
        <div className="header_outer">
          <div className="sec_container">
            <Header />
          </div>
        </div>
      

      {/* Order completed start */}
      <div className="order_placed_outer">
        <div className="sec_container">
          <div className="order_placed_inner" >
            <div className="thanks" style={{textAlign:'center'}} >
              <img src="/assets/images/check-green_1.gif" alt="" width={'180px'}/>
            <h1 style={{fontSize:'54px',color:'#000',fontWeight:600,margin:'10px 0'}}>Thank you</h1>
            <p style={{fontSize:'18px'}}>Your order has been placed!</p>

            <strong style={{fontWeight:'600',color:'#000',fontSize:'30px',marginTop:'10px',display:'block'}}>Order I'd : {orderCompleted?.id}</strong>
      
            <Link to="/" style={{    background: '#ba1f10',
              color: '#fff',
              padding: '10px 15px',
              display: 'inline-block',
              marginTop: '20px',
              borderRadius: '10px'}}>Go to Home</Link>
            </div>
          </div>
        </div>
      </div>
      {/* Order completed ends */}


      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
    </div>
  )
}
