import React, { useEffect, useState } from "react";
import Header from "../Header";

import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer";
import CartSteps from "./CartSteps";
import { clearCart, removeItem, setCouponData, updateQuantity } from "../../store/slices";
import { Link } from "react-router-dom";

import api from "../../axios/api";
import { toast } from "react-toastify";


export default function Cart() {

  
  const dispatch = useDispatch();
  const cartData = useSelector((store) => store.cartData.items);
  const accessToken = useSelector((store) => store.isAccessToken);
  const [selectedOption, setSelectedOption] = useState();
  const [loader,setLoader]=useState(null)


  const couponData= useSelector((store)=>store.couponData)

  
  


  function calculateTotalPrice() {
    let TotalPrice = 0;
    cartData?.forEach((products) => {
      TotalPrice += Math.round(products?.qty * products?.price);
    });
    return TotalPrice;
  }


  useEffect(() => {
    cartData?.forEach((product) => {
      setSelectedOption(product?.qty);
    })
  }, [cartData])




  console.log(selectedOption,"selectedOption1")

  const handleChange = (e, productId) => {
    const newQuantity = parseInt(e.target.value);
    dispatch(updateQuantity({ id: productId, qty: newQuantity }));
  };
  let shippingCosts = 0;
  if (calculateTotalPrice() < 500) {
    shippingCosts = 50
    
  }

  const [coupanCodeText, setCoupanCodeText] = useState(null);

  function grandTotal() {
    let grandTotal = 0
    //  grandTotal =   discountPrice + shippingCosts + calculateTotalPrice();

    if(couponData.desc_amt){
    grandTotal = (shippingCosts + calculateTotalPrice()) - couponData.desc_amt;
  }else{
    grandTotal = shippingCosts + calculateTotalPrice();
    }
  
    return grandTotal
  }

  const couponCodeApi = async () => {
    try {
      setLoader(true)
      const response = await api.post(`${process.env.REACT_APP_BASE_URL}coupon-apply`, { coupon_code: coupanCodeText }, accessToken);
      
      if (response.data.status === 200) { 
        dispatch(setCouponData(response.data.data))
        setLoader(false) 
        setCoupanCodeText(null)
      }
      else {
        toast.error(response.data.message)
        setLoader(false)
      }
    } catch (err) {
      console.error('API response :', err)
    }
  }



  const handleCoupon = () => {
    couponCodeApi();
  }

  const clearCartHandler = () => {
    dispatch(clearCart());
  }

  const removeItemHandler = (id) => {
    dispatch(removeItem({ id }));
  };

  const isLoggedIn = useSelector((store) => store.isLoggedInUser)


  const removeCoupon=()=>{
    dispatch(setCouponData({}))

  }


  
  return (
    <div className="wrapper cart_wrapper">

      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>

      

      <div className="cart_section_outer">
        <CartSteps shoppingcartImg={'../assets/images/shop_cart_icon_active.svg'} isCartPageActive={true} checkoutImg={'../assets/images/checkout_white.svg'} orderImg={'../assets/images/order_complete_white.svg'} />
        <div className="sec_container">
          <div className="cart_section_inner">

            {cartData.length !== 0 ? (
              <>
                <div className="cart_data_inner">
                  <div>
                    <div className="heading_outer">
                      <span>Your Cart </span>
                      <b><span onClick={clearCartHandler} className="clear_cart">Clear Cart</span>- ({cartData?.length})</b>
                    </div>
                    <div className="all_cart_products_outer">
                      {cartData?.map((products, id) => {
                        return (
                          <div
                            className="all_cart_products"
                            key={products?.id}
                          >

                            <div className="imgb_outer">

                              <div className="imgb">
                                <Link to={`/productdetail/${products?.slug}`}>
                                  <img src={`${process.env.REACT_APP_FOR_API_URL}${products?.productimg}`} alt="" />
                                  {/* <img src={`${process.env.REACT_APP_FOR_API_URL}${homeApiData?.banners[0]?.image}`} alt="" /> */}
                                </Link>
                              </div>


                              <Link to={`/productdetail/${products?.slug}`} style={{ width: '250px' }}>
                                <span className="name" style={{ fontWeight: '500', fontSize: '18px' }}>{products?.name} - ({products.unit}{products.msr_unit})</span>
                              </Link>
                            </div>



                            <span className="weight"> {products?.qty} qty.</span>

                            {/* <select name="" id="" value={selectedOption} onChange={handleChange} > */}
                            <select
                              name=""
                              id=""
                              value={products?.qty}
                              onChange={(e) => handleChange(e, products?.id)}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                            <span className="price">
                              Rs. {Math.trunc(products?.price)}
                            </span>
                            <span className="prod_total">
                              Rs. {Math.round(products?.qty * products?.price)}
                            </span>
                            <span className="remove_item" style={{ cursor: 'pointer' }} onClick={() => removeItemHandler(products?.id)}>
                              <i className="fa-solid fa-trash-can"></i>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="total_box">
                    <div className="grand_total">
                      <span>Grand Total: </span>
                      <b> Rs.{calculateTotalPrice()}</b>
                    </div>

                    {/* <div className=" disc_price">
                      <span>Discount</span>
                      <b>Rs. {discountPrice} </b>
                    </div> */}

                    <div className="shipping_cost">
                      <span>Shipping Cost</span>
                      <b>Rs. {shippingCosts}</b>
                    </div>
                    
                    {Object.keys(couponData).length === 0 ?
                    <div className="coupon_code">
                      <input type="text" value={coupanCodeText} onChange={(e) => setCoupanCodeText(e.target.value)} placeholder="Coupon code" />
                      <button type="submit" onClick={handleCoupon} className={!coupanCodeText && 'disabled'} disabled={!coupanCodeText}>{loader ?'Applying...':'Apply Coupon'}</button>
                    </div>
                    :
                    <>
                    <div className="coupon_disc">
                      <span>Coupon Discount</span>
                      <b style={{color:'green',fontWeight:'600'}}>- Rs. {couponData.desc_amt} </b>
                    </div>
                    <div className="cpn_code_box" style={{
                      background:'#f3fbf4',
                      display:"flex",
                      justifyContent:'space-between',
                      alignItems:'center',
                      padding:'6px 13px',
                      borderRadius:'33px',
                      fontSize:'13px',
                      color:'#000',
                      fontWeight:'600'
                      
                      }}>
                    {couponData.coupon_code}
                    <span onClick={removeCoupon} style={{cursor:'pointer',color:'#17af26',fontWeight:'400'}}>Remove</span>
                    </div>
                    </>
                    
                  }                   
                    <span className="free_ship_msg" style={{display:'block'}}>Get Free  <b style={{ color: "#000" }}>Shipping</b> for orders over <b style={{ color: "red" }}>Rs.500</b></span>
                    <div className="place_order">
                      <Link to="/checkout" className={!isLoggedIn ? 'disabled' : ''}>Proceed to Checkout | Rs.{grandTotal()}</Link>
                    </div>
                    {/* {!isLoggedIn && <div style={{ color: 'red', fontWeight: 600 }}>Kindly Login To Checkout</div>} */}
                    {/* <div className="payment_by">
                      <span>SECURE PAYMENTS PROVIDED BY</span>
                      <div className="payment_images">
                      
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            ) : (
              <div className="no_products">
                <img src="/assets/images/No_products_found.png" alt="" />
                <span>
                  No Products <b>Found</b>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  );
}
