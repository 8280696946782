import React from "react";
import { Link, useLocation } from "react-router-dom";


import CategoryNav from "./CategoryNav";

export default function NavBar() {
  const location = useLocation();

    
    const closeBtn=()=>{
        document.body.classList.remove('modal-open');
    }
    const navClicked=(e)=>{

        if(e.target.href !==null){
            document.body.classList.remove('modal-open');
        }
        

    }
  return (
    <div>
      

      <div className="navigation_outer">
        <div className="close_outer">
        <div className="close" onClick={closeBtn}>
        <i className="fa-solid fa-xmark"></i>
        </div>
        </div>
        <ul onClick={navClicked}>
          <li>
            <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
          </li>
          <li>
            <Link to="/categories" className={location.pathname === '/products' ? 'active' : ''}>All Products</Link>

            <CategoryNav/>
          </li>
          <li>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>
          </li>
          <li>
            <Link to="/outlets" className={location.pathname === '/outlets' ? 'active' : ''}>Outlets</Link>
          </li>
          <li>
            <Link to="/recipes" className={location.pathname === '/recipes' ? 'active' : ''}>Recipes</Link>
          </li>
          <li>
            <Link to="/bloglisting" className={location.pathname === '/bloglisting' ? 'active' : ''}>Blogs</Link>
          </li>
          {/* <li>
                <Link to="/">User Stories</Link>
            </li> */}
          <li>
            <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact Us</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
