import React, { useState } from "react";

import Header from "../components/Header";

import Footer from "../components/Footer";
import api from "../axios/api";
import { toast } from "react-toastify";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      try {
        const postData = { name:formData?.name,email:formData?.email,mobile:formData?.phoneNumber,message:formData?.message};
         const response = await api.post(`${process.env.REACT_APP_BASE_URL}contact-us-query-send`,postData);
         if(response.status === 200){
          toast.success(response?.data?.message,{toastId: "1"});
          setFormData({
            name: '',
            email: '',
            phoneNumber: '',
            message: ''
          });
         }
      } catch (error) {
        console.error('API error:', error);
      }
  };

  return (
    <div className="wrapper ">
      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
      <div className="about_wrapper contact_wrapper">
      <img src="/assets/images/fidalgo_contact_1.jpg" alt="" />
        <div className="sec_container">
          <div className="about_inner contact_form">
            {/* <h1>Contact Us</h1> */}
              <div className="form_box">
                <form onSubmit={handleSubmit}>
                  <div className="form_field">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_field">
                    <label htmlFor="Email Address">Email Address</label>
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_field">
                    <label htmlFor="">Phone Number</label>
                    <input
                      type="text"
                      placeholder="Enter Your Phone Number"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form_field">
                    <label htmlFor="">Message</label>
                    <textarea
                      placeholder="Enter Your Message"
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="form_field btn">
                  <button type="submit">Submit</button>
                  </div>
                </form>
              </div>
          </div>
        </div>
      </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  );
}
