import React from 'react'

export default function Loader({products}) {
  return (
    <div className="shimmer_outer">
      {products ? 
      <div className="shimmer products_shimmer">
      <div className="wrapper">
        <div className="image-card animate"></div>
        <div className="stroke animate title"></div>
        <div className="stroke animate link"></div>
        <div className="stroke animate description"></div>
      </div>
      <div className="wrapper">
        <div className="image-card animate"></div>
        <div className="stroke animate title"></div>
        <div className="stroke animate link"></div>
        <div className="stroke animate description"></div>
      </div>
      <div className="wrapper">
        <div className="image-card animate"></div>
        <div className="stroke animate title"></div>
        <div className="stroke animate link"></div>
        <div className="stroke animate description"></div>
      </div>
      <div className="wrapper">
        <div className="image-card animate"></div>
        <div className="stroke animate title"></div>
        <div className="stroke animate link"></div>
        <div className="stroke animate description"></div>
      </div>
      <div className="wrapper">
        <div className="image-card animate"></div>
        <div className="stroke animate title"></div>
        <div className="stroke animate link"></div>
        <div className="stroke animate description"></div>
      </div>
      <div className="wrapper">
        <div className="image-card animate"></div>
        <div className="stroke animate title"></div>
        <div className="stroke animate link"></div>
        <div className="stroke animate description"></div>
      </div>
    </div>
     :
<div className="shimmer">
  <div className="wrapper">
    <div className="image-card animate"></div>
    <div className="stroke animate title"></div>
    <div className="stroke animate link"></div>
    <div className="stroke animate description"></div>
  </div>
  <div className="wrapper">
    <div className="image-card animate"></div>
    <div className="stroke animate title"></div>
    <div className="stroke animate link"></div>
    <div className="stroke animate description"></div>
  </div>
  <div className="wrapper">
    <div className="image-card animate"></div>
    <div className="stroke animate title"></div>
    <div className="stroke animate link"></div>
    <div className="stroke animate description"></div>
  </div>
  <div className="wrapper">
    <div className="image-card animate"></div>
    <div className="stroke animate title"></div>
    <div className="stroke animate link"></div>
    <div className="stroke animate description"></div>
  </div>
  <div className="wrapper">
    <div className="image-card animate"></div>
    <div className="stroke animate title"></div>
    <div className="stroke animate link"></div>
    <div className="stroke animate description"></div>
  </div>
  <div className="wrapper">
    <div className="image-card animate"></div>
    <div className="stroke animate title"></div>
    <div className="stroke animate link"></div>
    <div className="stroke animate description"></div>
  </div>
</div>
}
</div>

  )
}
