
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://jsonplaceholder.typicode.com/', 
  timeout: 10000, 
  headers: {
    'Content-Type': 'application/json',
    
    
  },
});

instance.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    // Check if authToken is provided, and if so, add it to the headers
    const authToken = config.headers['Authorization'];
    if (authToken) {
      config.headers['Authorization'] = ` ${authToken}`;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;