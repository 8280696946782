import React from "react";
import parse from 'html-react-parser';

export default function Advantages({homeApiData}) {
  console.log(homeApiData?.primary_content?.title,"homeApiData")
  return (
    <ul className="advantages_inner">
      <li>
        <div className="imgb">
          
          <img src={`${process.env.REACT_APP_FOR_API_URL}${homeApiData?.primary_content?.image}`} alt="" />
          
        </div>
        <div className="text">
          <span>{homeApiData?.primary_content?.title}</span>
          <p>
            {parse(homeApiData?.primary_content?.content)}
          </p>
        </div>
      </li>
      
    </ul>
  );
}
