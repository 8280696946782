import axios from 'axios';
import React from 'react'

export default async function PaymentProcess(req,res) {
    
        try {
          // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint you want to fetch data from
          const apiEndpoint = 'http://localhost:3000/paymentprocess?id=MT7850590068188104';
          
          // Make a GET request to the external API
          const response = await axios.get(apiEndpoint);
      
          // Extract the data from the response
          const data = response.data;
      
          console.log(data)
          // Send the data back to the client
          res.status(200).json(data);
        } catch (error) {
          console.error('Error fetching data:', error);
          res.status(500).json({ error: 'Internal Server Error' });
        }
      
  return (
    <div>PaymentProcess</div>
  )
}
