import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import api from '../axios/api';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';


export default function CategoryList() {
    const [catDataList,setCatDataList]= useState('');

    const catList = async () => {
        try {
          const response = await api.get(`${process.env.REACT_APP_BASE_URL}category`);
          setCatDataList(response?.data?.data)
          // Handle the response data as needed, e.g., set it to state
        } catch (error) {
          console.error('API error:', error);
          // Handle the error, e.g., set an error state or show an error message
        }
      };
    
        
        
    
    
    useEffect(()=>{
      catList();
    },[])
    

  return (
    <>
    <div className="wrapper">
      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>

      <div className="cat_list_outer">
        <div className="sec_container">
        
    {catDataList.length>0 ?
        <ul className="cat_list_inner">
          {catDataList?.map((item) => (
          <li key={item?.id}>
            <Link to={item?.slug}>
                <img src={item?.image} alt="" />
            <span>
            {item?.name}
            </span>
            </Link>
          </li>
          )
          )}
          
        </ul>
        :
        <Loader products={true} />
        }
        
        </div>
        </div>

<div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
        </>
  )
}
