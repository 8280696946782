import React, { useEffect, useMemo, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import SearchBar from "../components/SearchBar";
import { Link, useLocation } from "react-router-dom";


export default function NoProducts(){

const { search } = useLocation();
// const searchParams = new URLSearchParams(search);
const searchParams = useMemo(() => new URLSearchParams(search), [search]);




const [searchData, setSearchData] = useState(
  searchParams.get("search") || null
);

useEffect(() => {
  setSearchData(searchParams.get("search") || null);

  
}, [ searchParams]);


    return(
        <>
        <div className="wrapper">
      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
<p style={{textAlign:'center',margin:'20px 0 40px',fontSize:'24px',color:'#000',fontWeight:'600'}}>Your search for <span style={{color:'#5291e7',fontSize:'24px',fontWeight:'600'}}>"{searchData}"</span> was not found.
<br/><br/> Check <Link to="/categories" style={{fontSize:'24px',fontWeight:'600',color:'#5291e7'}}>All Products</Link>
</p>

      {/* <SearchBar/> */}
<div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
        </>
    )
}