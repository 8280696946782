

import React, { useEffect, useState } from "react";
import api from "../axios/api";
import { useSelector } from "react-redux";
import Header from "../components/Header";

import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Loader from "../components/Loader";



export default function OrderDetail() {

    let url = window.location.href;
    let lastIndex = url.lastIndexOf('/');
    let orderId = url.substring(lastIndex + 1);
    const accessToken = useSelector((store) => store.isAccessToken);

    const [trackOrder, setTrackOrder] = useState(null);

    useEffect(() => {

        const OrderDetail = async () => {
            try {
                const response = await api.get(`${process.env.REACT_APP_BASE_URL}orders-list/${orderId}`, accessToken);
                setTrackOrder(response?.data?.data);

            }
            catch (error) {
                console.error("API error:", error);
            }
        };

        OrderDetail();
    }, [orderId, accessToken])


    if (trackOrder === null) return <Loader />
    return (
        <div className="order_completed order_status_detail">
            <div className='wrapper '>
                <div className="header_outer">
                    <div className="sec_container">
                        <Header />
                    </div>
                </div>
                

                {/* Order completed start */}
                <div className="order_placed_outer">

                    <div className="sec_container">
                        <Link to="/" style={{
                            background: '#ba1f10',
                            color: '#fff',
                            padding: '10px 15px',
                            
                            borderRadius: '10px',
                            margin:'20px 30px 0px auto',
                            display:'block',
                            maxWidth:'max-content',
                            

                        }}>Go to Home</Link>
                        <div className="order_placed_inner" >
                            <strong style={{ fontWeight: '600', color: '#000', fontSize: '30px', display: 'block' }}>Order I'd : {trackOrder?.id}</strong>
                            <div className='address'>Address : {trackOrder?.address1} {trackOrder?.address2}</div>
                            <div className='address city'>City : {trackOrder?.city} </div>
                            <div className='address state'>State : {trackOrder?.state} </div>
                            <div className='address pincode'>Pin Code : {trackOrder?.pincode} </div>
                            <div className='address pincode'>Mobile Number : {trackOrder?.mobile_number} </div>

                            <div className='status'>
                                Status :
                                <b>
                                    {trackOrder?.status === 1 ? ' Confirmed' :
                                        trackOrder?.status === 2 ? ' Dispatched'
                                            : ' Delivered'}
                                </b>

                            </div>

                            <div className="products_list">
                                {trackOrder?.products?.map((item, id) => (
                                    <>
                                    <div>
                                        
                                            <img src={`${process.env.REACT_APP_FOR_API_URL}${item?.image}`} alt="" width="100px" />
                                        <div>{item?.name}</div>
                                        <div>{item?.quantity} qty.</div>
                                        <div>Rs. {item?.price}</div>
                                        </div>

                                    </>



                                ))}
                            </div>
                            <div style={{textAlign:'right'}}>Order Amount : <b style={{color:'#000',fontWeight:600}}>{ trackOrder?.final_amount}</b></div>
                            

                        </div>
                    </div>
                </div>
                {/* Order completed ends */}


                <div className="footer_outer">
                    <div className="sec_container">
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}