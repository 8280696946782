import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import api from '../axios/api';
import Header from '../components/Header';
import parse from 'html-react-parser';

import Footer from '../components/Footer';

export default function RecipesDetail() {

  const [recipesDetail, setRecipesDetail] = useState(null);

  const { slug } = useParams();

  

  useEffect(() => {
    const BlogDetail = async () => {
      try {
        const response = await api.get(`${process.env.REACT_APP_BASE_URL}recipies-details/${slug}`);
        
        setRecipesDetail(response?.data?.data);
      }
       catch (error) {
        
        console.error("API error:", error);

      }
    };
    BlogDetail();
    
      window.scrollTo({
             top: 0,
             left: 0,
             behavior: "smooth"
           });
   
  }, [slug]);

  
  return (
    <div className='wrapper '>
            <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
        
        
        
      
      <div className="about_wrapper blog_detail_wrapper receipe_detail">
        <div className='imgb'>
      <img src={`${process.env.REACT_APP_FOR_API_URL}${recipesDetail?.image}`} alt="" style={{maxHeight:"350px"}}/>
      </div>
      <div className="sec_container">
        <div className="about_inner blog_detail_inner">
          
          <h1>{recipesDetail?.title}</h1>
          {parse(`<p>${recipesDetail?.description}</p>`)}
          
        </div>
        </div>
      </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
      
    </div>
  )
}
