import React, { useEffect, useState } from 'react'
import api from '../axios/api';
import Header from './Header';
import parse from 'html-react-parser';
import Footer from './Footer';

export default function Terms() {

  const [terms,setTerms]= useState('');
  useEffect(() => {
    const Terms = async () => {
      try {

        const response = await api.get(`${process.env.REACT_APP_BASE_URL}settings`);
        
        setTerms(response?.data?.data[0].terms)

      }
       catch (error) {
        
        console.error("API error:", error);

      }
    };
    Terms();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });

  }, [])
  return (

    <div className='wrapper '>
            <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
          
        
    <div  className='privacy_sec'>
    <h1>Terms & Conditions</h1>
      <div className="sec_container">
        <p style={{margin:'50px 0px'}}>

        
      {parse(terms)}
      </p>
      </div>
      </div>
    <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
      
    </div>
  )
}
