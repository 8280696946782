import React, { useEffect, useState } from 'react';
import api from '../../axios/api';
import { useNavigate } from 'react-router-dom';

export default function Category({ categoryName }) {
    const [catDataList, setCatDataList] = useState([]);

    const navigate = useNavigate();

    const catList = async () => {
        try {
            const response = await api.get(`${process.env.REACT_APP_BASE_URL}category`);
            setCatDataList(response?.data?.data);
        } catch (error) {
            console.error('API error:', error);
        }
    };

    useEffect(() => {
        catList();
    }, []); // Empty dependency array to ensure this runs only once

    const handleRadioChange = (slug) => {
        navigate(`/categories/${slug}`);
    };

    return (
        <>
            {catDataList.length > 0 &&
                <ul className="product_cat_ul">
                    {catDataList?.map((item) => (
                        <li key={item?.id}>
                            <input
                                type="radio"
                                name="cat_list"
                                id={item?.slug}
                                value={item?.name}
                                onChange={() => handleRadioChange(item?.slug)}
                                checked={categoryName === item?.slug}
                            />
                            <label htmlFor={item?.slug}>{item?.name}</label>
                        </li>
                    ))}
                </ul>
            }
        </>
    );
};
