import React, { useEffect, useState } from "react";
import api from "../axios/api";
import Header from "./Header";
import parse from 'html-react-parser';

import Footer from "./Footer";

export default function RefundPolicy() {
  const [refund, setRefund] = useState("");
  useEffect(() => {
    const Refund = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_BASE_URL}settings`
        );

        setRefund(response?.data?.data[0].refund_policy);
      } catch (error) {
        console.error("API error:", error);
      }
    };
    Refund();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="wrapper ">
      <div className="header_outer">
        <div className="sec_container">
          <Header />
        </div>
      </div>
      
          
        
      <div className="privacy_sec">
        <h1>Refund Policy</h1>
        <div className="sec_container">
          <p style={{ margin: "50px 0px" }}>{parse(refund)}</p>
        </div>
      </div>
      <div className="footer_outer">
        <div className="sec_container">
          <Footer />
        </div>
      </div>
    </div>
  );
}
