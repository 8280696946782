import React from "react";

export default function PopUp({ setHomePopUp }) {
  return (
    <div className="popup_outer">
      <div className="overlay"></div>
      <div className="popup_inner">
        <div className="content_box">
          <span onClick={() => setHomePopUp(false)}>X</span>
          <div className="content">
            <div className="title">The Website is under construction</div>
            <p>
              <b>Note :</b> All data currently is just{" "}
              <b style={{ color: "#000", fontWeight: "600" }}>Test</b> data on
              the website
            </p>

            <div className="close" onClick={() => setHomePopUp(false)}>
              Close
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
